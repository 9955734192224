import useServices from '../../hooks/service'
import Button from '../button'
import Typography from '../typography'
import ServiceCard from './components/service-card'
import ServiceCardBase from './components/service-card-base'
import { servicesList, servicesListViewAll } from './services-list.module.scss'

interface ServicesListProps {
  maxItems?: number
}

const ServicesList = ({ maxItems }: ServicesListProps) => {
  const services = useServices()

  return (
    <ul className={servicesList}>
      {(maxItems ? services.slice(0, maxItems) : services).map(item => (
        <ServiceCard key={item.id} {...item} />
      ))}
      {maxItems && maxItems < services.length ? (
        <ServiceCardBase
          className={servicesListViewAll}
          link={{ to: '/services' }}
        >
          <Typography variant="h4">View all services</Typography>
          <Button variant="primary">Take me there</Button>
        </ServiceCardBase>
      ) : null}
    </ul>
  )
}

export default ServicesList
