import Button from '../../../button'
import Container from '../../../container'
import Icon, { IconProps } from '../../../icon'
import Typography from '../../../typography'
import {
  work,
  workContainer,
  workContent,
  workInner,
  workLogos,
} from './work.module.scss'

const logos: IconProps['name'][] = [
  'reactjs',
  'nodejs',
  'ember',
  'ios',
  'android',
  'responsive',
]

const Work = () => (
  <div className={work}>
    <Container className={workContainer}>
      <div className={workInner}>
        <div className={workContent}>
          <Typography variant="h3" color="inherit">
            We&apos;d love to work with you.
          </Typography>
          <Typography gutterTop variant="body1" color="inherit">
            Almost a decade of experience... and counting.
          </Typography>
          <Typography gutterTop variant="body1" color="inherit">
            We&apos;ll help you bring your project ideas to life by delivering
            well crafted, long lasting, production software on time. Whether
            you&apos;re a new startup looking for a technical partner to help
            get it right, or an enterprise organization looking for experts to
            help level up your technical goals.
          </Typography>
        </div>
        <div className={workLogos}>
          {logos.map(logo => (
            <Icon
              key={logo}
              name={logo}
              width={100}
              height={80}
              color="white"
            />
          ))}
        </div>
      </div>
      <Button variant="primary" link={{ to: '/contact' }}>
        Let&apos;s get to work!
      </Button>
    </Container>
  </div>
)

export default Work
