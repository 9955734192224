import Icon from '../../../icon'
import Typography from '../../../typography'
import { content, contentAppFlowIcon } from './content.module.scss'

const Content = () => (
  <div className={content}>
    <Icon name="app-flow" className={contentAppFlowIcon} />
    <div>
      <Typography variant="h3">
        Cutting edge solutions, from idea to launch.
      </Typography>
      <Typography gutterTop variant="body1" color="textSecondary">
        We help you bring your ideas to realization by consciously breaking down
        actionable steps and processes to achieve your goal. We’re in the
        business of making you look good while making your business run better
        than you thought possible.
      </Typography>
    </div>
  </div>
)

export default Content
