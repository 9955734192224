import { StaticImage } from 'gatsby-plugin-image'
import { useSiteMetadata } from '../../../../hooks/site'
import { Color } from '../../../../utils/colors'
import Button, { LearnMoreButton } from '../../../button'
import Container from '../../../container'
import HexAnimation from '../../../hex-animation'
import Typography from '../../../typography'
import {
  hero,
  heroCenteredContent,
  heroContainer,
  heroContent,
  heroFeaturedAppImage,
  heroFeaturedAppPhone,
  heroFeaturedAppWrapper,
  heroFeaturedContainer,
} from './hero.module.scss'

const Hero = () => {
  const { title, description } = useSiteMetadata()

  return (
    <div className={hero}>
      <Container className={heroContainer}>
        <div className={heroContent}>
          <Typography variant="h2">{title}</Typography>
          <Typography gutterTop variant="body1" color="textSecondary">
            {description}
          </Typography>
          <Button variant="primary" link={{ to: '/services' }}>
            Learn more about what we do
          </Button>
        </div>
        <div className={heroFeaturedContainer}>
          <div className={heroFeaturedAppWrapper}>
            <StaticImage
              src="../../../../images/iphone-shell.png"
              quality={100}
              alt="Iphone"
              className={heroFeaturedAppPhone}
            />
            <StaticImage
              src="../../../../images/ajo-home.png"
              quality={100}
              alt="Ajo home"
              className={heroFeaturedAppImage}
            />
          </div>
          <div className={heroCenteredContent}>
            <Typography variant="body1" color="textSecondary">
              We built a savings app for an innovative fintech startup
            </Typography>
            <LearnMoreButton link={{ to: '/work' }} />
          </div>
        </div>
      </Container>
      <HexAnimation
        icons={(
          ['red', 'blue', 'green', 'blue', 'green', 'red'] as Color[]
        ).map(color => ({ color, fade: true }))}
      />
    </div>
  )
}

export default Hero
