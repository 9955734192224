import classNames from 'classnames'
import useRipple from '../../../../hooks/ripple'
import { IconName } from '../../../../icons'
import { LearnMoreButton } from '../../../button'
import Icon from '../../../icon'
import Typography from '../../../typography'
import ServiceCardBase from '../service-card-base'
import { serviceCard, serviceCardActive } from './service-card.module.scss'

export interface ServiceCardProps {
  icon: IconName
  name: string
  description: string
  link: string
}

const ServiceCard = ({ icon, name, description, link }: ServiceCardProps) => {
  const { isActive, insertRipple, removeRipple } = useRipple()

  return (
    <ServiceCardBase
      className={classNames(serviceCard, {
        [serviceCardActive]: isActive,
      })}
      link={{ to: link }}
      onMouseEnter={insertRipple}
      onMouseLeave={removeRipple}
    >
      <Icon name={icon} width={72} height={72} />
      <Typography variant="h5">{name}</Typography>
      <Typography gutterTop gutterBottom>
        <span dangerouslySetInnerHTML={{ __html: description }} />
      </Typography>
      <LearnMoreButton />
    </ServiceCardBase>
  )
}

export default ServiceCard
