import Callout from '../callout'
import Page from '../page/page'
import Content from './components/content'
import Hero from './components/hero'
import Services from './components/services'
import Work from './components/work'

const PageIndex = () => (
  <>
    <Hero />
    <Work />
    <Page container>
      <Content />
      <Services />
    </Page>
    <Callout />
  </>
)

export default PageIndex
