import PageIndex from '../components/page-index/index'
import Seo from '../components/seo'

const Index = () => (
  <>
    <Seo />
    <PageIndex />
  </>
)

export default Index
