import { MouseEventHandler, useCallback, useState } from 'react'
import { ripple as rippleIn } from './ripple.module.scss'

const useRipple = (): {
  isActive: boolean
  insertRipple: MouseEventHandler
  removeRipple: MouseEventHandler
} => {
  const [isActive, setIsActive] = useState(false)

  const insertRipple: MouseEventHandler = useCallback(event => {
    const button = event.currentTarget

    // Create circle for ripple
    const circle = document.createElement('span')
    const diameter = Math.max(button.clientWidth, button.clientHeight)
    const radius = diameter / 2

    // Figure out position to place circle for animation
    const topPos = button.getBoundingClientRect().top
    const leftPos = button.getBoundingClientRect().left

    circle.style.width = `${diameter}px`
    circle.style.height = `${diameter}px`
    circle.style.left = `${event.clientX - leftPos - radius}px`
    circle.style.top = `${event.clientY - topPos - radius}px`
    circle.classList.add(rippleIn)

    // Check for any existing ripples and remove them
    const ripple = button.getElementsByClassName(rippleIn)[0]
    if (ripple) ripple.remove()

    // Add circle span to element
    button.appendChild(circle)
    setIsActive(true)
  }, [])

  const removeRipple: MouseEventHandler = useCallback(event => {
    const ripple = event.currentTarget.getElementsByClassName(rippleIn)[0]
    if (ripple) ripple.remove()
    setIsActive(false)
  }, [])

  return {
    isActive,
    insertRipple,
    removeRipple,
  }
}

export default useRipple
