import classNames from 'classnames'
import Card, { CardContent, CardProps } from '../../../card'
import {
  serviceCardBase,
  serviceCardBaseContent,
} from './service-card-base.module.scss'

export type ServiceCardBaseProps = CardProps

const ServiceCardBase = ({
  className,
  children,
  link,
  ...rest
}: ServiceCardBaseProps) => (
  <Card {...rest} as="li" className={classNames(serviceCardBase, className)}>
    <CardContent className={serviceCardBaseContent} link={link}>
      {children}
    </CardContent>
  </Card>
)

export default ServiceCardBase
